<template>


  <h3>Přihlašování na tréninky</h3>
  <h5>Individuální tréninky</h5>
    <p>Podle toho, co&nbsp;chcete se svým psem řešit může individuální trénink probíhat venku nebo přímo u&nbsp;vás doma.</p>
    <h6>Cena</h6>
    <p>600 Kč za&nbsp;první hodinu tréninku<br>
      za&nbsp;každou další započatou čtvrthodinu si účtuji 100&nbsp;Kč<br>
      při pravidelných individuálních trénincích se od druhého tréninku cena první hodiny každé lekce snižuje na 500&nbsp;Kč</p>
    <h6>Doprava</h6>
      <p>v oblasti mezi&nbsp;Nymburkem, Jičínem a Mladou Boleslaví je účtován příplatek 100&nbsp;Kč za&nbsp;cestu<br>
      tento poplatek neúčtuji, pokud se trénink koná v&nbsp;Rožďalovicích nebo pokud jste přihlášeni na&nbsp;předem vypsaný trénink<br>
      pro delší vzdálenosti se cena dopravy domlouvá individuálně</p>
  <h5>Skupinové tréninky a socializační procházky v Jičíně a Mladé Boleslavi</h5>
    <!--<p>Vypisuji spíše sporadicky. Pokud jsou, najdete je na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu.</a></p>
    <p>Pokud vypsané nejsou a měli byste o skupinku zájem, <router-link to="/kontakt">napište mi</router-link>, něco vymyslíme :)</p>
    <p>Od dubna 2024 jsem vypsala tréninky a socializační vycházky na pondělní a čtvrteční podvečer do Mladé Boleslavi a úterní/středeční podvečer do Jičína.</p>-->
    <p>Přihlašování Mladá Boleslav <a href="https://clovek-a-pes-spolu.dogres.cz/trainings?place_id=1099" target="_blank">ZDE</a>.</p>
    <p>Jičín přihlašování <a href="https://clovek-a-pes-spolu.dogres.cz/trainings?place_id=484" target="_blank">ZDE</a>.</p>

  
  <!-- <h5>Osenice - kurzy</h5>
    <p>První kurzy v&nbsp;Osenicích začnou <b>3.&nbsp;1.&nbsp;25</b> <br>
    V&nbsp;Osenicích budou kurzy v&nbsp;nevytápěné hale - znamená to dobré osvětlení a sucho, ale stále je tam teplota podobná venkovní.<br>
    Kvůli pronájmu haly vychází kurzy v&nbsp;Osenicích o&nbsp;něco dráž než&nbsp;jinde.</p>
    <p>Přihlásit se můžete na&nbsp;Základy rodinného psa 2.0 <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/357715" target="_blank">na&nbsp;mém dogresu</a> nebo <a href="https://zko-nymburk.dogres.cz/trainings/357740" target="_blank">dogresu ZKO Nymburk</a></p>
    <p>Konat se tam bude i kurz Pozornost a přivolání, tam už se dá přihlásit jen <a href="https://zko-nymburk.dogres.cz/trainings/357684" target="_blank">přes&nbsp;dogres ZKO Nymburk</a></p>



  <h5>Mladá Boleslav - kurzy</h5>
  <p>První běh kurzů v Mladé Boleslavi začne na přelomu října a listopadu. Přihlašování je přes <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">můj dogres</a> 
  a hlásíte se na první tréninkovou lekci. Týden před ní proběhne ještě úvodní teoretická lekce - určená pro školku a základy rodinného psa, nicméně vy, kdo se přihlásíte na přivolávače, si ji určitě můžete také přijít poslechnout :).</p>
  <p>Přihlásit se můžete na následující kurzy:</p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249544" target="_blank">Školka pro štěňátka</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249556" target="_blank">Základy rodinného psa pro dospělé psy</a></p>
  <p><a href="https://clovek-a-pes-spolu.dogres.cz/trainings/249565" target="_blank">Přivolávači</a></p>-->
  <h5>Mladá Boleslav - kurzy</h5>
  <p>Nové kurzy v Boleslavi začnou v dubnu</p>
  <p>V tuto chvíli se můžete hlásit na:<br>
  <!--<a href="https://clovek-a-pes-spolu.dogres.cz/trainings/365166" target="_blank" ><b>Základy rodinného psa 2.0 - kurz pro psy všeho věku </b></a> - začínáme 27. 2. <br>-->
  <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/365208" target="_blank" ><b>Pozornost a přivolání</b></a> - začínáme 10. 4. 
  </p>
  <!--<p>Předpokládám start dalšího běhu na začátku příštího roku. Veškeré informace budou jako vždy zde i na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu</a> </p>-->

  <h5>Jičín - kurzy</h5>
  <!--<p>Další kurzy v Jičíně začaly <b>25.9.</b></p> v tuto chvíli je poslední volné místo a <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/334789" target="_blank" >přihlásit se můžete zde</a>
  <p>Start dalšího běhu ředpokládám na začátku příštího roku. Veškeré informace budou jako vždy zde i na <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">mém dogresu</a> </p>
    Přihlašování je přes <a href="https://clovek-a-pes-spolu.dogres.cz/trainings" target="_blank">můj dogres</a> 
  a hlásíte se na první tréninkovou lekci. Týden před ní proběhne ještě úvodní teoretická lekce - určená pro školku a základy rodinného psa, nicméně vy, kdo se přihlásíte na přivolávače, si ji určitě můžete také přijít poslechnout :).</p>-->
  <p>Nové kurzy v Jičíně začnou v dubnu</p>
  <p>Přihlásit se můžete na následující kurzy:<br>
  <!--<a href="https://clovek-a-pes-spolu.dogres.cz/trainings/365156" target="_blank"><b>Základy rodinného psa 2.0 - kurz pro psy všeho věku</b></a> - začínáme 26. 2.<br>-->
  <a href="https://clovek-a-pes-spolu.dogres.cz/trainings/365217" target="_blank"><b>Pozornost a přivolání</b></a> - začínáme 9. 4. </p>

  <h5>ZKO Nymburk - kurzy</h5>
    <p>Další kurzy v ZKO Nymburk začnou v březnu</p>

     <!--A jakmile budou vypsané, najdete zde propozice i odkazy pro přihlašování.
    <p>Začátek dalšího běhu kurzů předpokládám na přelomu podzimu a zimy. <b>Pokud už teď víte, že chcete na mé kurzy, napište mi, když vás bude víc, začnou dříve :) </b></p>
    <p>Vylepšila jsem kurz Základy rodinného psa, tak aby byl pro vás ještě užitečnější, přehlednější a abyste si z něj spolu se svým psím parťákem odnesli více vědomostí, dovedností a zkušeností. proto ho nyní najdete jako <b>Základy rodinného psa 2.0</b></p>
    <p>Přihlašování je přes <a href="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" target="_blank">dogres ZKO</a> 
      Kurzy na rozdíl od jednotlivých lekcí nejsou hrazeny kreditovým systémem</p>

    <p><a href="https://zko-nymburk.dogres.cz/trainings/364625" target="_blank"><b>Pozornost a přivolání</b></a> <br>
    <a href="https://zko-nymburk.dogres.cz/trainings/364681" target="_blank"><b>Základy rodinného psa pro&nbsp;dospělé psy a puberťáky&nbsp;2.0</b></a> <br>
    <a href="https://zko-nymburk.dogres.cz/trainings/364634" target="_blank"><b>Základy rodinného psa pro&nbsp;štěňátka&nbsp;2.0</b></a> <br>
    <a href="https://zko-nymburk.dogres.cz/trainings/364738" target="_blank"><b>Pokračovací kurz pro&nbsp;absolventy Základů rodinného psa</b></a> -->
  <p>Pro ty, kdo preferují všední dny večer začneme 25. 3.<br>
  <a href="https://zko-nymburk.dogres.cz/trainings/373862" target="_blank"><b>Pozornost a přivolání</b></a> 

  </p>

    <!--<p><a href="https://zko-nymburk.dogres.cz/trainings/325961" target="_blank">Ošetření se souhlasem - pátek</a></p>
    <p></p>-->
    <!--<p><a href="https://zko-nymburk.dogres.cz/trainings/274451" target="_blank">Pro&nbsp;psí důchodce a/nebo citlivky</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325952" target="_blank">Ošetření se&nbsp;souhlasem - sobota</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325973" target="_blank">Kurz pro reaktivce - jak být s&nbsp;ostatními psy a lidmi v&nbsp;pohodě</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/305031" target="_blank">Z&nbsp;bojínka hrdinou</a></p>
    <p><a href="https://zko-nymburk.dogres.cz/trainings/325989" target="_blank">Všeobecná sportovní přípravka</a></p>-->

  <h5>ZKO Nymburk - jednotlivé tréninky</h5>
    <p>Na tréninky v rámci <a href="https://zkonymburk.blogspot.com/" target="_blank">ZKO Nymburk</a> se přihlašujte přes <a href="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" target="_blank">dogres ZKO</a>. 
    Na&nbsp;cvičáku funguje kreditový systém, pro přihlášení je tedy nutné mít zakoupené kredity.</p>
    <!--<iframe src="https://zko-nymburk.dogres.cz/trainings?trainer_id=924" width="100%" height="400px"></iframe>-->

    <p><b>Těším se na vás!</b></p>

</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({ title: 'Člověk a pes spolu - trenér psů Zdenka Cihlářová - přihlašování na tréninky a kurzy' })
  },


}
</script>

